import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../../components/Layout';

const PoliticalAdvocacyPage = () => (
	<Layout title='Political Advocacy'>
		<div className='row justify-content-between align-items-center'>
			<div className='col-md-6 col-lg-5 order-1 px-3'>
				<h3 className='mt-3 display-7 raleway-700'>Political Advocacy</h3>
				<p>
					ASGSR is a nonprofit bringing together scientists and engineers from all disciplines, united by
					research that utilizes space and microgravity. Many of our projects use the International Space
					Station National Lab, and as such, political activism in favor of{' '}
					<a href='https://www.nasa.gov/' target='_blank'>
						NASA
					</a>{' '}
					and the{' '}
					<a href='http://www.iss-casis.org' target='_blank'>
						ISS
					</a>{' '}
					are very important to us!
				</p>
				<p>
					In 2004, many of NASA’s space research programs were cut because of priority funding for other
					projects. As a result, the number of people and students involved in space research dropped
					dramatically (2200 scientists plus 2350 students in 2004, vs. 360 scientists plus 275 students in
					2014).
				</p>
				<p>
					Now that the ISS is functional and taking on research projects, the size of the space research
					community (and funding for it!) must increase to make full use of the station. Research funding is
					limited, with a research budget of $82.5 million and only a fraction of it going to grants.
					Compared to the cost of the station ($150 billion), and the cost to maintain it ($3 billion/year),
					the amount of funding for research is very low!
				</p>
				<p>
					It is important to invest more into space research to ensure that we utilize the space station to
					full capacity and get more return on the large investment of building the station. Furthermore, the
					station is set to retire by 2030 eaving only 8 years to get as many projects up there as possible.
					Finally, it is important that the U.S. has a plan to maintain ability to perform microgravity
					research in partnership with private space actors after the ISS is retired.
				</p>
				<p>
					To read more about our political advocacy trip and see some of the pictures we took, see our reports
					for previous years trips:
					<ul>
						<li>
							<Link to='/news/2022-dc-trip'>2022 DC Trip</Link>
						</li>
						<li>
							<Link to='/news/2018-dc-trip'>2018 DC Trip</Link>
						</li>
						<li>
							<Link to='/news/2017-dc-trip'>2017 DC Trip</Link>
						</li>
					</ul>
				</p>
			</div>
			<div className='col-md-6 order-0 order-md-2 my-3'>
				<StaticImage
					src='../../images/about/political_advocacy.jpg'
					alt='Space.'
					layout='fullWidth'
					formats={['auto', 'webp']}
					placeholder='blurred'
					class='br'
				/>
			</div>
		</div>
	</Layout>
);

export default PoliticalAdvocacyPage;
